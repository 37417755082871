import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import '../Project/project.css';
import ScrollToTopButton from '../ScrollButton';
import Amaryllis1 from '../../images/Project_Images/Amaryllis_1.jpeg';
import Amaryllis2 from '../../images/Project_Images/Amaryllis_2.jpeg';
import Amaryllis3 from '../../images/projects-slider/amaryllis-3.webp';
import Flocert1 from '../../images/Project_Images/Flocert_1.jpeg';
import Flocert2 from '../../images/Project_Images/Flocert_2.jpeg';
import Flocert3 from '../../images/projects-slider/flocert-1.webp';
import Hometheater1 from '../../images/projects-slider/ht-mangalore-1.webp';
import Hometheater2 from '../../images/projects-slider/ht-mangalore-2.webp';
import Hometheater3 from '../../images/projects-slider/ht-mangalore-3.webp';
import Sabic1 from '../../images/Project_Images/Sabic_1.png';
import Sabic2 from '../../images/projects-slider/sabic-bangalore.webp';
import Sabic3 from '../../images/Project_Images/Sabic_3.jpg';
import DRC1 from '../../images/Project_Images/drc_1.jpg';
import DRC2 from '../../images/Project_Images/DRC_2.jpg';
import DRC3 from '../../images/projects-slider/drc-mysore.webp';
import Hospira1 from '../../images/Project_Images/Hospira_1.jpg';
import Hospira2 from '../../images/Project_Images/hospira_2.jpg';
import Hospira3 from '../../images/projects-slider/hospira-chennai.webp';
import CMC1 from '../../images/Project_Images/cmc_building.jpeg';
import CMC2 from '../../images/Project_Images/cmc-2.jpg';
import CMC3 from '../../images/Project_Images/cmc_3.avif';
import KIS1 from '../../images/Project_Images/KISchool_1.jpg';
import KIS2 from '../../images/projects-slider/proj-kodai-2.webp';
import KIS3 from '../../images/projects-slider/proj-kodai-3.webp';
import Generalmotor1 from '../../images/Project_Images/GM_Building.webp';
import Generalmotor2 from '../../images/Project_Images/GM_2.webp';
import Generalmotor3 from '../../images/projects-slider/gm-bangalore.webp';
import DPS1 from '../../images/Project_Images/DPSchool_1.jpg';
import DPS2 from '../../images/Project_Images/DPSchool_2.jpg';
import DPS3 from '../../images/Project_Images/DPSchool_3.webp';
import Navelbase1 from '../../images/Project_Images/Navelbase_1.jpg';
import Navelbase2 from '../../images/projects-slider/proj_13.webp';
import Navelbase3 from '../../images/projects-slider/proj_13.webp';
import Zoho1 from '../../images/Project_Images/Zoho_1.jpeg';
import Zoho2 from '../../images/projects-slider/zoho-chennai.webp';
import Zoho3 from '../../images/Project_Images/zoho_3.webp';
import Twitter1 from '../../images/Project_Images/twitter_building.jpeg';
import Twitter2 from '../../images/projects-slider/twitter-india.webp';
import Twitter3 from '../../images/Project_Images/Twitter_3.jpg';
import Wipro1 from '../../images/Project_Images/wipro_1.webp';
import Wipro2 from '../../images/new_pro_img/wipro_2.jpg';
import Wipro3 from '../../images/Project_Images/wipro_3.webp';
import Goldman1 from '../../images/Project_Images/Goldman_building.webp';
import Goldman2 from '../../images/Project_Images/Goldman_4.jpeg';
import Goldman3 from '../../images/projects-slider/goldman-bangalore.webp';
import EIBFS1 from '../../images/Project_Images/EMIRATES_1.jpg';
import EIBFS2 from '../../images/Project_Images/Emirates_2.jpg';
import EIBFS3 from '../../images/Project_Images/Emirates_3.webp';
import MoF1 from '../../images/Project_Images/Musium_Furute_1.webp';
import MoF2 from '../../images/Project_Images/Museum_2.jpg';
import MoF3 from '../../images/Project_Images/Musium_3.jpeg';
import Louvre1 from '../../images/Project_Images/Louvre_1.jpg';
import Louvre2 from '../../images/Project_Images/Louvre-2.jpg';
import Louvre3 from '../../images/Project_Images/Louvre_3.jpg';
import Palace1 from '../../images/Project_Images/Presidential_1.jpg';
import Palace2 from '../../images/Project_Images/Presidential_2.webp';
import Palace3 from '../../images/Project_Images/Presidential_3.jpg';
import KAPSARC1 from '../../images/Project_Images/Kapsarc_1.webp';
import KAPSARC2 from '../../images/Project_Images/Kapsarc_2.jpg';
import KAPSARC3 from '../../images/Project_Images/Kapsarc_3.jpg';
import Ferrari1 from '../../images/Project_Images/ferrari_1.webp';
import Ferrari2 from '../../images/Project_Images/ferrari_2.jpeg';
import Ferrari3 from '../../images/Project_Images/ferrari_3.jpeg';
import Bosch1 from '../../images/projects-slider/bosch.webp';
import Bosch2 from '../../images/projects-slider/bosch-bangalore.webp';
import Bosch3 from '../../images/projects-slider/bosch-meeting-hall.webp';
import Teleperformance1 from '../../images/projects-slider/project-teleperformance1.webp';
import Teleperformance2 from '../../images/projects-slider/project-teleperformance2.webp';
import Teleperformance3 from '../../images/projects-slider/project-teleperformance3.webp';
import Bhutan1 from '../../images/Project_Images/GCIT_Build.jpg';
import Bhutan2 from '../../images/bhutanmain.jpeg';
import Bhutan3 from '../../images/bhutan3.jpg';
import Biocon1 from '../../images/Project_Images/biocon_Build.jpg';
import Biocon2 from '../../images/Project_Images/biocon.jpeg';
import Biocon3 from '../../images/biocon2.jpeg';
import Epam1 from '../../images/Epam.jpeg';
import Epam2 from '../../images/Project_Images/epam_1.jpeg';
import Epam3 from '../../images/Project_Images/epam_2.jpeg';
import hdfc1 from '../../images/hdfc.jpeg';
import hdfc2 from '../../images/hdfc2.jpeg';
import hdfc3 from '../../images/hdfc3.jpeg';
import rio1 from '../../images/rio1.jpeg';
import rio3 from '../../images/rio5.jpeg';
import rio2 from '../../images/rio4.png';
import myntra1 from '../../images/Project_Images/myntra_build.jpeg';
import myntra2 from '../../images/myntra2.jpeg'
import myntra3 from '../../images/myntra.jpeg';
import ipop1 from '../../images/ipopaudio.jpeg';
import ipop2 from '../../images/ipop2.jpg';
import ipop3 from '../../images/ipop3.jpg';
import library1 from '../../images/kalaignar1.jpg';
import library2 from '../../images/kalaignar2.jpg';
import library3 from '../../images/kalaignar3.jpeg';
import dlf1 from '../../images/Project_Images/DLF_Build.webp';
import dlf2 from '../../images/dlf3.jpeg';
import dlf3 from '../../images/dlf2.jpeg';
import HTR1 from '../../images/Project_Images/HTR1.jpg';
import HTR2 from '../../images/Project_Images/HTR2.jpg';
import HTR3 from '../../images/Project_Images/HTR3.jpeg';

const projectsData = [
    {
        id: 33,
        name: "Home Theater, Rajapalayam",
        location: "Rajapalayam",
        client: "Cubix Marketing",
        architect: "",
        aboutText: `For any home theater project, ASI aims to create an immersive, high-quality acoustic experience. This state-of-the-art installation will transform the space into a hub of entertainment. Perfect for enjoying movies, music, and more, we deliver superior sound clarity, enhancing the overall experience with top-tier acoustic treatments.`,
        scope: "Our comprehensive home theater installation includes the supply and installation of Fabriwall for the walls and Decopanel for the ceiling. Our goal is to enhance and ensure a superior cinematic experience tailored to the client's needs.",
        images: [HTR1, HTR2, HTR3],
    },
    {
        id: 32,
        name: "HDFC Project",
        location: "Mumbai",
        client: "BNP Interiors",
        architect: "Mr. Nirav",
        aboutText: `HDFC Bank Residential Training Centre: Nestled within Palava, a burgeoning township on the outskirts of Mumbai, this innovative centre combines training and residential spaces across 8 floors. Notably, it aspires to achieve both a platinum green building rating and a Health + Wellbeing certification.`,
        scope: "Our well-trained technicians transformed the HDFC auditorium into an acoustic wonderland with FabriWall excellence. The before-and-after images speak volumes—where once there was monotony, now there’s vibrancy.",
        images: [hdfc1, hdfc2, hdfc3],
    },
    {
        id: 31,
        name: "Google Rio",
        location: "Bangalore, Karnataka",
        client: "BNP Interiors",
        architect: "",
        aboutText: `This 12-storey development boasts some of India's largest single floor plates, housing over 7,000 employees. Each floor features self-sustainable yet connected communities, fostering interaction between employees and various teams. This design enhances collaboration and productivity, creating a dynamic and integrated workspace. The building's scale and design set a benchmark for large office spaces in India.`,
        scope: "For this project, our scope includes the supply of FOAMSTOP Polyurethane. This high-performance material ensures superior soundproofing and insulation. Trust us to deliver quality and efficiency with every installation.",
        images: [rio1, rio2, rio3],
    },
    {
        id: 30,
        name: "Myntra",
        location: "Bangalore, Karnataka",
        client: "Myntra Jabong India Pvt Ltd.",
        architect: "",
        aboutText: `Myntra stands as one of India's largest fashion e-commerce stores, offering a wide range of fashion and lifestyle products for men, women, and kids. It boasts high-quality clothes, branded footwear, and bags. Additionally, Myntra sells beauty and personal care items, as well as home and living accessories. Their diverse product range caters to every style and need, ensuring top-notch quality.`,
        scope: "Our scope included the installation of Fabrisorb panels and Clouds. With these exclusive  elements which are crucial in enhancing both the acoustic quality and visual appeal of the space, we aimed to create a dynamic atmosphere at MYNTRA.",
        images: [myntra1, myntra2, myntra3],
    },
    {
        id: 29,
        name: "Ipop Audio-Experience Centre",
        location: "Pondicherry",
        client: "Mr. Manikandan",
        architect: "",
        aboutText: `IPOP Audio is the go-to spot for home theatre enthusiasts, featuring a state-of-the-art experience center. Customers can explore the latest audio-visual technology and enjoy unparalleled sound quality. Committed to delivering immersive experiences, IPOP Audio has carved out its place as a leader in the home theatre industry, setting new standards for excellence.`,
        scope: "Assessment, Planning, Customisation and Supply of D'ecopanel to achieve a great appeal to acoustics as well as aesthetics. Our tailored approach delivered top-quality results, transforming their space effectively.",
        images: [ipop1, ipop2, ipop3],
    },
    {
        id: 28,
        name: "Kalaignar Centenary Library",
        location: "MADURAI, TAMILNADU",
        client: "Dhanvi Enterprises",
        architect: "",
        aboutText: `The Kalaignar Centenary Library was inaugurated by Hon’ble Chief Minister Thiru M.K. Stalin on July 15, 2023. This impressive six-storey building spans a whopping 2.13 lakh square feet and is situated on 2.70 acres of land. It’s a true architectural marvel! The library boasts an array of features like Art Gallery, Multi-purpose Hall,Children’s Theatre, Conference Hall, Rare Collections.`,
        scope: " Supply and installation of Decopanel designer panels- a delightful fusion of aesthetics and acoustics- for the vibrant kids’ section and Fabriwall system- an elegant solution that highlights acoustic performance in the auditorium.",
        images: [library1, library2, library3],
    },
    {
        id: 27,
        name: "DLF The Camellias",
        location: "Gurugram",
        client: "",
        architect: "",
        aboutText: `DLF Camellias in Golf Course Road, Gurgaon is one of the finest luxury condominium project built by the prestigious firm of DLF. This high-end residential project offers premium residences in the configuration of 4BHK, 5BHK and 6BHK units.`,
        scope: "Supply & Installation of Fabriwall Acoustic Plaster in ceilings.",
        images: [dlf1, dlf2, dlf3],
    },
    {
        id: 26,
        name: "Epam",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `EPAM India has fast grown to be a 7,100+ people organization across five locations, its a part of a global network of advisors, consultants, engineers, scientists and creatives who enable customers to be competitive and disruptive in the marketplace through innovative technology solutions while helping them to navigate successfully through multiple waves of technology change.`,
        scope: "Supply and Installation of Acoustic Plaster.",
        images: [Epam1, Epam2, Epam3],
    },
    {
        id: 25,
        name: "Biocon",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Biocon Limited is an Indian biopharmaceutical company based in Bangalore. It was founded by Kiran Mazumdar-Shaw in 1978. The company manufactures generic active pharmaceutical ingredients that are sold in approximately 120 countries, including the United States and Europe. Biocon is the first company worldwide to develop recombinant human insulin (rh-insulin) on a Pichia expression system.`,
        scope: "Supply and Installation of FABRIWALL system.",
        images: [Biocon1, Biocon2, Biocon3],
    },
    {
        id: 24,
        name: "GCIT College Auditorium",
        location: "Bhutan",
        client: "Royal Government of Bhutan",
        architect: "",
        aboutText: `Gyalpozhing College of Information Technology - GCIT is a fascinating institution that’s shaping the tech-savvy minds of tomorrow and it holds a special place as one of the integral colleges within the Royal University of Bhutan.  GCIT aims to be a leading institution in software technology and interactive design. It’s like a tech-savvy village where equations meet hiking trails.`,
        scope: "Supply and installation of Polysorb Wall Panels and Clouds.Our skilled technicians armed with their creativity had transformed the auditorium that once echoed into a space of harmony.",
        images: [Bhutan1, Bhutan2, Bhutan3],
    },
    {
        id: 23,
        name: "Teleperformance",
        location: "INDORE, MP",
        client: "",
        architect: "",
        aboutText: `Teleperformance SE, headquartered in France, stands as a global leader in omnichannel customer experience management. Spanning operations in 91 countries, they excel in seamless customer interactions across diverse markets. From customer acquisition to technical support, debt collection, and social media services, Teleperformance leaves no stone unturned in delivering exceptional experiences.`,
        scope: "In this dynamic setting we supplied and expertly installed D’ecopanel—an acoustic marvel that transcends mere functionality. D’ecopanel seamlessly blends aesthetics and sound control, transforming spaces into havens of tranquility and style.",
        images: [Teleperformance1, Teleperformance2, Teleperformance3],
    },
    {
        id: 22,
        name: "Robert Bosch Engineering & Business Solutions Pvt.Ltd.",
        location: "Bangalore, India",
        client: "",
        architect: "",
        aboutText: `In India, Bosch has set up its first sales agency in 1922 at Kolkata and started its manufacturing operations in 1951. This has grown over the years to 18 manufacturing sites and seven development and application centres. With a legacy of 100 years, Bosch India is a leading technology and services provider in the areas of Mobility Solutions, Industrial Technology, Consumer Goods, and Energy and Building Technology. Bosch India has the largest development centre outside Germany for end to end engineering and technology solutions.`,
        scope: "Supply & Installation of Fabriwall Stretch Fabric Acoustic Wall Panel System.",
        images: [Bosch1, Bosch2, Bosch3],
    },
    {
        id: 21,
        name: "Ferrari World",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `The 86,000 m2 enclosed area of Ferrari World Abu Dhabi can fit 7 football fields in 'head to toe’. The total roof area of 200,000 m2 uses enough aluminum to cover 16,750 Ferraris, or if you laid the roof flat you could place 20,100 Ferrari's side by side and end to end. If Ferrari World Abu Dhabi was turned upright, it would be the tallest man made structure in the world at over 300 floors!`,
        scope: "We installed fabric wrapped acoustic wall panel system on the wall to increase the sound absorption there by to reduce reverberation.",
        images: [Ferrari1, Ferrari2, Ferrari3],
    },
    {
        id: 20,
        name: "KAPSARC",
        location: "Riyadh, Saudi Arabia",
        client: "",
        architect: "",
        aboutText: `An independent non-profit organization dedicated to researching economics and technology across all platforms of energy. Designed by Zaha Hadid Architects, the modular structure is a living, organic scheme where expansions to the building are allowed to grow and multiply like cells – without compromising the visual integrity of the project. Construction Area: 66,000m2; Site Area: 530,000 m2; Footprint Area: 28,500 m2`,
        scope: "Supply & installation of acoustic wall panels, Echo Panels & Acoustic Treatment for the walls & movable partitions.",
        images: [KAPSARC1, KAPSARC2, KAPSARC3],
    },
    {
        id: 19,
        name: "Presidential Palace",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `The location of the new Presidential Palace is in Ras Al Akhdar peninsula adjacent to the Emirates Palace Hotel, Abu Dhabi. The development will encompass approximately 150 hectares, and will include the Palace and the required ancillary buildings. The Palace will exceed 160000m2 of built area and will contain several VIP wings, a Ministers wing, and several Majlis and Dining Halls.`,
        scope: "Supply & Installation of acoustical Wall panels. It was a great opportunity to be part of this iconic project.",
        images: [Palace1, Palace2, Palace3],
    },
    {
        id: 18,
        name: "Louvre",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `Abu Dhabi’s Tourism Development & Investment Company (TDIC) has completed the installation of the dome structure in its upcoming attraction Louvre Abu Dhabi, Designed by architect Jean Nouvel. Louvre Abu Dhabi encompass 9,200 square metres of art galleries including a 6,681 sqm Permanent Gallery and a 2,364 sqm Temporary Gallery.`,
        scope: "We have been instrumental in treating the Echo and optimizing the Reverberation Times by introducing more than 22,000SQM of acoustic wood wool.",
        images: [Louvre1, Louvre2, Louvre3],
    },
    {
        id: 17,
        name: "The Museum of Future",
        location: "Dubai, UAE",
        client: "",
        architect: "",
        aboutText: `The Museum of the Future is a visionary cultural institution currently under construction in Dubai, a showplace for a new era - a center of creativity and hope where you can see, touch and shape our shared future.`,
        scope: "Acoustical Plaster ceiling in Auditorium below the walkway & Hall area.",
        images: [MoF1, MoF2, MoF3],
    },
    {
        id: 16,
        name: "Emirates Institute of Banking & Financial Studies",
        location: "Dubai, UAE",
        client: "",
        architect: "",
        aboutText: `Emirates Institute For Banking and Financial Studies (EIBFS) has made quantum leaps by delivering world className education, training and allied services in the area of Banking and Finance at its three campuses strategically located at Sharjah, Abu Dhabi and Dubai.`,
        scope: "Acoustical Interior fit out which include In-house acoustic consultancy & Design, Acoustical wall treatment & ceiling.",
        images: [EIBFS1, EIBFS2, EIBFS3],
    },
    {
        id: 15,
        name: "Delhi Public School",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Delhi Public School Bangalore East is a member of the prestigious DPS family comprising of over a hundred schools located in India as well as overseas.The DPS network in India spans across the geographical extent of our country and beyond. The schools abroad include the ones at Nepal, Indonesia, Singapore, United Arab Emirates, Qatar and Kuwait.`,
        scope: "We were entrusted with installation of Polysorb Baffles along with Polysorb Wall Panels in the swimming pool area. Our design and Acoustic treatment optimized the reverberation.",
        images: [DPS1, DPS2, DPS3],
    },
    {
        id: 14,
        name: "Kodaikanal International School",
        location: "Kodaikanal",
        client: "",
        architect: "",
        aboutText: `Kodaikanal International School (KIS), nestled in the picturesque hills of Kodaikanal, Dindigul, Tamil Nadu, spans 43 acres. This co-educational independent residential school offers grades P-12 offering a robust curriculum and vibrant community. With over a century-long legacy of excellence, Kodaikanal international School's commitment to quality education matches its scenic heights, inspiring students.`,
        scope: "Supply & Installation of acoustical wall panels for the music jamming studio. Acoustically treating the ceiling and walls of the music room to reduce the reverberation time to provide the best performance results when loud music is being played.",
        images: [KIS1, KIS2, KIS3],
    },
    {
        id: 13,
        name: "Home Theater, Mangalore",
        location: "Mangalore",
        client: "",
        architect: "",
        aboutText: `For any home theater project, ASI aims to create an immersive, high-quality acoustic experience. This state-of-the-art installation will transform the space into a hub of entertainment. Perfect for enjoying movies, music, and more, we deliver superior sound clarity, enhancing the overall experience with top-tier acoustic treatments. Through our expertise, we deliver tailored soulutions.`,
        scope: "We are responsible for the complete home theater installation, including the supply and installation of acoustic walls and flooring. Our focus is on enhancing sound quality and aesthetics, ensuring an unparalleled cinematic experience.",
        images: [Hometheater1, Hometheater2, Hometheater3],
    },
    {
        id: 12,
        name: "Hospira Healthcare",
        location: "Chennai",
        client: "",
        architect: "",
        aboutText: `Hospira Healthcare India PVT. LTD. previously known as Ojas Pharmaceuticals India PVT. LTD., specializes in manufacturing injectable formulations and active pharmaceutical ingredients (APIs). It continues to deliver high-quality pharmaceutical products, contributing significantly to global healthcare. Their expertise in APIs and formulations underscores their pivotal role in the industry.`,
        scope: "At Hospira Healthcare project, our scope included the installation of FabriWALL system which enhanced sound quality and control within their facilities. Our tailored solution ensured an efficient environment to meet their high standards.",
        images: [Hospira1, Hospira2, Hospira3],
    },
    {
        id: 11,
        name: "Christian Medical College",
        location: "Vellore, Tamil Nadu",
        client: "",
        architect: "",
        aboutText: `The Christian Medical College and Hospital (CMC),Vellore, Tamil Nadu, India is a private, educational and research institute that includes a network of primary, secondary and tertiary care hospitals . Founded in 1900 by American missionary Dr. Ida S. Scudder, CMC has brought many significant achievements to India. The institution pioneers medical advancements and exceptional healthcare services.`,
        scope: "We treated the wall partition for 39dB acoustic isolation, added NRC 0.9 absorption wall paneling, and installed suspended ceilings with acoustic isolation. The project covered 2361 sq.ft., including room renovations with the latest technology.",
        images: [CMC1, CMC2, CMC3],
    },
    {
        id: 10,
        name: "Flocert India",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Flocert is a leading certifier providing verification services to Fair-trade organizations worldwide. Over the past decade, they have supported the impressive growth of Fair-trade. This movement has positively impacted millions globally. Flocert's dedication ensures the integrity and success of Fair-trade initiatives. They remain at the forefront of promoting equitable trading practices.`,
        scope: "Supply & Installation of acoustical Fabric panels with echo eliminator as backing material for the conference room to reduce the reverberation time to provide better speech intelligibility.",
        images: [Flocert1, Flocert2, Flocert3],
    },
    {
        id: 9,
        name: "Amaryllis Healthcare Pvt Ltd, Bangalore",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Amaryllis Healthcare Private Limited, an ISO Certified Company, has become a vital partner in India's healthcare sector in recent years. Their product range includes disposable surgical drapes, gowns, and a variety of hygiene and protective products. This wide array of offerings ensures comprehensive support for healthcare professionals, enhancing both safety and efficiency across the industry.`,
        scope: "Supply and installation of Noise S.T.O.P Vinyl Barrier and Fabri<span style='color: red;'>wall</span> system with Glass wool to increase sound isolation property of wall and to reduce reverberation time in the conference room which provides better speech intelligibility.",
        images: [Amaryllis1, Amaryllis2, Amaryllis3],
    },
    {
        id: 8,
        name: "General Motors",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `General Motors India Private Limited, headquartered in Bangalore, is a strategic partnership between General Motors and SAIC. The company designs, develops, and manufactures vehicles tailored to the Indian market while contributing to GM's global programs. Despite ceasing car sales in India in 2017, GM India continues to operate its technical center in Bangalore, focusing on engineering and design innovations.`,
        scope: "At General Motors India Private Limited includes the design, supply, and installation of a state-of-the-art anechoic chamber. Our expertise ensured precision in sound testing and noise reduction, supporting GM's innovation and excellence.",
        images: [Generalmotor1, Generalmotor2, Generalmotor3],
    },
    {
        id: 7,
        name: "Navel Base Academy",
        location: "Vishakapatnam",
        client: "",
        architect: "",
        aboutText: `Naval Base Academy in Visakhapatnam is a prestigious institution dedicated to training future officers for the Indian Navy. The academy offers rigorous academic programs alongside intensive physical training, preparing cadets for their roles. With state-of-the-art facilities and a commitment to leadership and discipline, Naval Base Academy shapes the future of India's naval forces.`,
        scope: "Our scope at Naval Base Academy involves the supply and installation of advanced Fabrisorb panels. Our precision and expertise ensure the academy's spaces are equipped for excellence.",
        images: [Navelbase1, Navelbase2, Navelbase3],
    },
    {
        id: 6,
        name: "Twitter India",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Twitter, a vibrant online news and social networking platform, allows users to post and engage with messages known as tweets. Headquartered in San Francisco, California, Twitter, Inc. operates globally with over 25 offices. This dynamic service has revolutionized how information and conversations flow worldwide. It continues to be a driving force in real-time communication and connectivity.`,
        scope: "Now, Twitter’s offices boast enhanced acoustics, creating a more focused and productive atmosphere with our tailored acoustic wall treatments. Our solutions improved sound quality and reduced noise, perfect workspaces.",
        images: [Twitter1, Twitter2, Twitter3],
    },
    {
        id: 5,
        name: "Wipro Technologies",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Wipro Technologies Ltd., a division of Wipro Limited (NYSE:WIT),ranks among the largestglobal IT services, BPO, and Product Engineering companies. Beyond IT, Wipro leads in niche market segments of consumer products and lighting solutions. Their multifaceted expertise drives innovation across industries, solidifying their global presence. This leadership underscores Wipro's commitment to excellence and growth.`,
        scope: "At Wipro, we enhanced sound quality and aesthetics with our acoustic wall treatments. From consultation to installation, we provide toptier sound control solutions,transforming spaces into quieter, more productive environments.",
        images: [Wipro1, Wipro2, Wipro3],
    },
    {
        id: 4,
        name: "Goldman Sachs",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `The Goldman Sachs Group, Inc. is a leading global investment banking, securities and investment management firm that provides a wide range of financial services to a substantial and diversified client base that includes corporations, financial institutions, governments and individuals. Founded in 1869, the firm is headquartered in New York and maintains offices in all major financial centres around the world.`,
        scope: "Our scope includes comprehensive acoustic wall treatments tailored to enhance the acoustic quality and aesthetics of Goldman Sachs' global offices. From consultation to installation, we ensured Topnotch sound control solutions.",
        images: [Goldman1, Goldman2, Goldman3],
    },
    {
        id: 3,
        name: "Sabic Research & Technology Pvt. Ltd",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Sabic Research and Technology Private Limited, incorporated on 19 February 1998, operates as a Nongovernment company. It is registered with the Registrar of Companies in Bangalore. Sabic focuses on innovative research and technology developments in various sectors, driving forward advancements and solutions. The company's commitment to excellence drives industry transformation`,
        scope: "We expertly supplied and installedacoustical doors and partitions at Sabic, enhancing their spaces with superior soundproofing solutions. Sabic’s commitment to excellence is mirrored in our high- quality acoustical doors.",
        images: [Sabic1, Sabic2, Sabic3],
    },
    {
        id: 2,
        name: "DRC Cinemas",
        location: "Mysore",
        client: "",
        architect: "",
        aboutText: `<strong>Where Dreams Unfold on the Big Screen</strong> : In the heart of Mysore, DRC Cinemas stands tall—a beacon of entertainment and community. As the city’s first multiplex, it brings to life the dreams of countless Mysoreans. Top-of-the-line acoustics and projection, creates a mesmerizing ambiance. The stylish lobby and concession counters invite moviegoers, while diverse language films captivate audiences on the screens.`,
        scope: "In this project, we enhanced the auditory experience by ensuring that every whisper, every explosion, and every note resonates flawlessly within the theatre walls. It’s more than just sound—it’s an immersive journey.",
        images: [DRC1, DRC2, DRC3],
    },
    {
        id: 1,
        name: "Zoho Corporation",
        location: "Chennai",
        client: "",
        architect: "",
        aboutText: `<strong>Empowering Business Solutions</strong> : Zoho Corporation, a global leader in business software, thrives on innovation. Their suite of webbased tools and IT solutions caters to businesses worldwide. Zoho seamlessly bridges efficiency and effectiveness, driving productivity. With a focus on practicality, they set the standard in the industry.`,
        scope: `<strong>Enhancing Spaces with Acoustic Excellence:</strong> 
        We specialize in supplying and professionally installing two key elements:
        <ol style="margin-left: 20px;">
            <li><strong>Acoustic Panel System</strong></li>
            <li><strong>Acrylic Sheet</strong></li>
        </ol>`,
        images: [Zoho1, Zoho2, Zoho3],
    },
];

const ProjectCard = ({ project }) => {
    return (
        <div className='container mt-5 mb-5'>
            <div className='row'>
                <div className="col-lg-7 mb-5">
                    <div className='top-img'>
                        <img className='top' src={project.images[0]} alt={project.name} />
                    </div>
                    <div className='bottom-img d-flex'>
                        <div>
                            <img className='img-fluid bot-img1' src={project.images[1]} alt={`${project.name}-2`} />
                        </div>
                        <div>
                            <img className='img-fluid bot-img2' src={project.images[2]} alt={`${project.name}-3`} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 project-content">
                    <div className='rno d-flex'><span className='namespan'>{project.id.toString().padStart(2, '0')}. </span><h5>{project.name}</h5></div>
                    <div className='location d-flex'><p>Location :</p><span className='pl-1'>{project.location}</span></div>
                    <p className='client'>Client: {project.client}</p>
                    <p className='architech'>Architect: {project.architect}</p>
                    <p className='abt-project mb-0'>About Project:</p>
                    <p className='para-content' style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: project.aboutText }} />
                    <p className='scope-project mb-0'>Our Scope:</p>
                    <p className='para-content' style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: project.scope }} />
                </div>
            </div>
            <hr className='project-hr' />
        </div>
    );
}

function Project() {
    const projectsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(projectsData.length / projectsPerPage);

    const currentProjects = projectsData.slice((currentPage - 1) * projectsPerPage, currentPage * projectsPerPage);

    // Handle pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Helmet>
                <title>Turnkey Acoustic Projects - Acoustical Surfaces</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Projects Gallery" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/projects" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/projects" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/projects" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content projects">
                <div className='container-fluid p-0 pro-banner'>
                    <h3>Recently</h3>
                    <h1>Completed Projects</h1>
                </div>

                {currentProjects.map(project => (
                    <ProjectCard key={project.id} project={project} />
                ))}
                <ScrollToTopButton />

                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end pr-3 pb-3">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} aria-label="Previous">
                                &laquo;
                            </button>
                        </li>
                        {[...Array(totalPages).keys()].map(pageNumber => (
                            <li key={pageNumber + 1} className={`page-item ${currentPage === pageNumber + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)}>
                                    {pageNumber + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
                                &raquo;
                            </button>
                        </li>
                    </ul>
                </nav>

            </section>
            <Footer />
        </>
    );
}
export default Project;